<template>
	<div>
		<ApplyHeader v-if="!$isMobile.any" />
		<slot />
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ApplyHeader from '@/components/user/ApplyHeader.vue';

export default defineComponent({
	name: 'ApplyLayout',
	components: { ApplyHeader }
});
</script>
