<template>
	<header class="z-50 w-full bg-color-main flex justify-between">
		<nuxt-link to="/pwa" class="z-50">
			<NuxtImg
				v-if="$isMobile.any"
				src="/shared-components-nuxt3/svgs/hokify/logo-hy-white.svg"
				alt="hokify"
				class="h-8 w-8 my-2 mx-5 inline-block"
			/>
			<NuxtImg
				v-else
				src="/shared-components-nuxt3/svgs/hokify/logo-brand-white.svg"
				alt="hokify"
				class="h-10 my-3 ml-12 mr-5 inline-block"
			/>
		</nuxt-link>
	</header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	methods: {
		goBack() {
			this.$router.go(-1);
		}
	},
	mounted() {
		if (window.history.length <= 1 && this.$refs.historyback instanceof Element) {
			this.$refs.historyback.classList.add('hidden');
		}
	}
});
</script>

<style scoped src="@hokify/pwa-core-nuxt3/assets/styles/header.scss" lang="scss" />
